if(body.classList.contains('EventFormBlockPage')) {
    var subscribeButtons = document.getElementsByClassName('subscribe-now-button');

    Array.from(subscribeButtons).forEach(function (subscribeButton) {
        subscribeButton.addEventListener('click', function () {
            var eventID = this.getAttribute('href').split("#").pop();
            //Close all open modals
            var modals = document.getElementsByClassName('modal show');
            if (typeof modals != 'undefined') {
                Array.from(modals).forEach(function (modal) {
                    Modal.getOrCreateInstance(modal).hide();
                })
            }

            //Activate Event in Select field and slide down
            var selectField = document.getElementById('Form_EventSubscriptionForm_event-subscription-event');
            var options = Array.from(selectField.options)
            options.forEach((option, i) => {
                if (option.value === eventID) {
                    selectField.selectedIndex = i;
                    selectField.classList.add('is-valid');
                }
            })
            selectField.dispatchEvent(new Event('change'));

            var form = document.getElementById('event-subscription-form-wrapper');
            smoothScroll(form.id, navbar.offsetHeight + 50);
        })
    });


    window.removeURLParams = function (oldURL) {
        var index = 0;
        var newURL = oldURL;
        index = oldURL.indexOf('?');
        if (index == -1) {
            index = oldURL.indexOf('#');
        }
        if (index != -1) {
            newURL = oldURL.substring(0, index);
        }
        return newURL;
    };

    var EventBox = document.getElementById('Form_EventSubscriptionForm_event-subscription-event')

    EventBox.addEventListener('change', function (event) {
        event.preventDefault();
        var selectBox = document.getElementById('Form_EventSubscriptionForm_event-persons');
        selectBox.setAttribute('disabled', true);
        var selectedEventID = EventBox.options[EventBox.selectedIndex].value;
        var ajaxURL = removeURLParams('' + window.location + '') + 'ajaxGetSlotsLeft/' + selectedEventID;

        while (selectBox.options.length > 0) {
            selectBox.remove(0);
        }

        fetch(ajaxURL)
            .then(response => response.json())
            .then(data => {
                var slotsLeft = data.slotsleft;
                for (var i = 1; i <= slotsLeft; i++) {
                    var newOption = new Option(i + ' Person' + (i > 1 ? 'en' : ''), i);
                    selectBox.appendChild(newOption);
                }
                if (slotsLeft > 0) {
                    selectBox.removeAttribute('disabled');
                }
            })
            .catch(function () {
                var newOption = new Option('Leider ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.', 'error');
                selectBox.appendChild(newOption);
            })
    });
}
