// if document is loaded

window.addEventListener("DOMContentLoaded", function () {
    const layers = document.querySelectorAll(
        ".header-image-stack .header-image-stack-image"
    );

    if (layers.length > 1) {
        setInterval(() => {
            // create a class "active" that moves through all layers every 2 seconds
            const activeLayer = document.querySelector(
                ".header-image-stack .header-image-stack-image.active"
            );
            const nextLayer = activeLayer.nextElementSibling || layers[0];
            activeLayer.classList.remove("active");
            activeLayer.classList.add("inactive");
            nextLayer.classList.add("active");
            nextLayer.classList.remove("inactive");
        }, 2500);
    }
});
