/**
 * ##############################
 * Drittanbieter Bibliotheken
 * ##############################
 */

/* JS-Cookies */
const Cookies = require("js-cookie");
window.Cookies = Cookies;

/* Bootstrap */
const Alert = require("bootstrap/js/dist/alert");
window.Alert = Alert;

const Button = require("bootstrap/js/dist/button");
window.Button = Button;

const Collapse = require("bootstrap/js/dist/collapse");
window.Collapse = Collapse;

const Dropdown = require("bootstrap/js/dist/dropdown");
window.Dropdown = Dropdown;

const OffCanvas = require("bootstrap/js/dist/offcanvas");
window.OffCanvas = OffCanvas;

const Popover = require("bootstrap/js/dist/popover");
window.Popover = Popover;

const Modal = require("bootstrap/js/dist/modal");
window.Modal = Modal;

// Deaktivierte Scripte aus Bootstrap

// const Carousel = require('bootstrap/js/dist/carousel');
// window.Carousel = Carousel;

// const Scrollspy = require('bootstrap/js/dist/scrollspy');
// window.Scrollspy = Scrollspy;

// const Tab = require('bootstrap/js/dist/tab');
// window.Tab = Tab;

// const Toast = require('bootstrap/js/dist/toast');
// window.Toast = Toast;

// const Tooltip = require('bootstrap/js/dist/tooltip');
// window.Tooltip = Tooltip;

/* Font Awesome */
require("@fortawesome/fontawesome-free/js/fontawesome");
require("@fortawesome/fontawesome-free/js/solid");
require("@fortawesome/fontawesome-free/js/brands");
require("@fortawesome/fontawesome-free/js/regular");

import { tns } from "tiny-slider/src/tiny-slider";
window.tns = tns;

//ServiceCheck Widget Image Footer

!(function () {
    var e, t, n;
    ((n = (t = document).createElement("script")).type = "text/javascript"),
        /*e.src = "https://ext1.service-check.com/widgets/badge2018/35693b113a/sc_widget2018.js";*/
        (n.src =
            "https://ext1.service-check.com/widgets/badge2018/35693b113a/sc_widget2018.js"),
        (e = t.getElementsByTagName("script")[0]).parentNode.insertBefore(n, e),
        (n.onload = function () {
            "complete" === t.readyState ||
            ("loading" !== t.readyState && !t.documentElement.doScroll)
                ? new ServiceCheckImageWidget().init()
                : t.addEventListener("DOMContentLoaded", function () {
                      new ServiceCheckImageWidget().init();
                  });
        });
})();

function loadTheWidgetScript() {
    var c, d, e;
    d = document;
    e = d.createElement("script");
    e.type = "text/javascript";
    e.src =
        "https://ext1.service-check.com/widgets/widget_plus/MzU2OTNiMTEzYS4yODk=/sc_widget_plus.js";
    c = d.getElementsByTagName("script")[0];
    c.parentNode.insertBefore(e, c);
    e.onload = function () {
        if (
            d.readyState === "complete" ||
            (d.readyState !== "loading" && !d.documentElement.doScroll)
        ) {
            new ScWidgetPlus();
        } else {
            d.addEventListener("DOMContentLoaded", function () {
                new ScWidgetPlus();
            });
        }
    };
}

//ServiceCheck Widget Plus Block

(function () {
    document.getElementById("service-check-widget-plus-positioner") != null
        ? loadTheWidgetScript()
        : console.log("No Service Check found");
})();

/**
 * ##############################
 * Eigene Scripte
 * ##############################
 */
require("./helpers.js");
// require("./cookie-consent.js");
require("./mega-menu.js");
require("./page-header.js");
require("./events.js");
require("./privacy.js");
require("./navigation.js");
require("./recaptcha.js");
require("./forms.js");
require("./reviews.js");
require("./securemailto.js");
require("./modals.js");
require("./popover.js");
require("./products.js");
require("./hero.js");
require("./global-banner.js");
require("./nobilia.js");
require("./brandlogo.js");
require("./popup.js");

/**
 * ##############################
 * Styles
 * ##############################
 */
import "./../sass/app.sass";
