let offCanvas = document.querySelector(".standard-menu .offcanvas");
let hamburger = document.querySelector(".standard-menu .hamburger");

let mobileMenu = document.querySelector("#mobile-navigation");
let mobileHamburger = document.querySelector(".mega-menu .hamburger");

if (offCanvas != null) {
    offCanvas.addEventListener("show.bs.offcanvas", (e) => {
        hamburger.classList.add("is-active");
        hamburger.classList.remove("collapsed");
    });
}

if (hamburger != null) {
    offCanvas.addEventListener("hide.bs.offcanvas", (e) => {
        hamburger.classList.remove("is-active");
        hamburger.classList.add("collapsed");
    });
}

if (mobileMenu != null) {
    mobileMenu.addEventListener("show.bs.collapse", (e) => {
        if (e.target === mobileMenu) {
            console.log("show.bs.collapse");
            mobileHamburger.classList.add("is-active");
            mobileHamburger.classList.remove("collapsed");
        }
    });
}

if (mobileHamburger != null) {
    mobileMenu.addEventListener("hide.bs.collapse", (e) => {
        if (e.target === mobileMenu) {
            mobileHamburger.classList.remove("is-active");
            mobileHamburger.classList.add("collapsed");
        }
    });
}
