/**
 * --------------------------------------------------
 * ##################################################
 * ### JS-FUNKTIONEN FÜR DAS SORTIMENT & PRODUKTE ###
 * ##################################################
 * --------------------------------------------------
 */

let params;
let url;
let applyFilterButton = document.getElementById("apply-filter");
let deleteFilterButton = document.getElementById("delete-filter");
let rangeActionBar = document.getElementById("range-action-bar");
let activeFiltersWrapper = document.getElementById("active-filters");
let activeFiltersContainer = document.getElementById("active-filters-badges");
let filterBadges;

document.addEventListener("DOMContentLoaded", function () {
    var sliders = document.querySelectorAll(".product-slider");
    sliders.forEach(function (slider) {
        tns({
            container: slider,
            items: 1,
            mode: "carousel",
            slideBy: "page",
            autoplay: false,
            controlsContainer: document.querySelector(
                ".product-slider__controls"
            ),
            navContainer: document.querySelector(".product-slider__nav"),
            navAsThumbnails: true,
            nav: false,
            responsive: {
                991: {
                    nav: true,
                },
            },
        });
    });

    var sliderNavigations = document.querySelectorAll(".product-slider__nav");
    sliderNavigations.forEach(function (sliderNavigation) {
        tns({
            container: sliderNavigation,
            items: 5,
            mode: "carousel",
            slideBy: 1,
            loop: false,
            autoplay: false,
            nav: false,
            controlsContainer: document.querySelector(
                ".product-slider__controls"
            ),
        });
    });

    /**
     * Handelt es sich bei der aktuellen Seite um
     * eine Sortimentsseite (ausgenommen Produkt-Detail-Seiten)?
     */
    if (
        body.classList.contains("RangePage") &&
        !body.classList.contains("ProductPage")
    ) {
        /**
         * Extrahiere als Parameter aus der aktuellen URL, sortiere sie
         * alphabetisch und lege sie in ein hidden Textfeld, dass die
         * neue Filter-URL beinhaltet.
         */
        url = new URL(window.location.href);
        params = new URLSearchParams(url.search);
        params.sort();
        document.getElementById("filter-url").value = params;

        /**
         * ##############################################
         * ################ FILTERUNG ###################
         * ##############################################
         *
         * Druchlaufe jeden gefundenen Parameter der URL
         */
        params.forEach(function (value, key) {
            /**
             * Entferne das erste Filter-Badge. Das Objekt selbst
             * bleibt dabei erhalten nd dient später als Template für
             * andere aktive Filter.
             */
            let firstFilterBadge =
                document.getElementsByClassName("filter-badge")[0];
            let badgeClone;

            /**
             * Ist der aktuelle Parameter kein "order"- oder "page"-Parameter
             * durchlaufe die aktiven Filter und erstelle für jeden von ihnen
             * einen Eintrag in den aktiven Filtern.
             *
             * Diese Einträge werden anschliessend verbunden mit den Checkboxen
             * in der Filterübersicht. So weiss ein Filter-Badge welcher Filter
             * deaktiviert werden muss wenn man sein X klickt.
             *
             * Blende anschliessen die Sektion mit den aktiven Filter ein.
             * Diese ist anfangs ausgeblendet, um den Zurücksetzen-Link und das
             * Filter-Badge-Template zu verstecken.
             */
            if (key != "order" && key != "page" && key != "stage") {
                let values = value.split("_");

                firstFilterBadge.classList.add("d-none");

                values.forEach(function (avalue) {
                    //Referenziere die Checkbox die den Wert dieses Filters als ID hat
                    let checkbox = document.querySelector(
                        'input[type="checkbox"]#' + key + "-" + avalue + ""
                    );

                    // aktiviere diese Checkbox wenn der Filter dazu aktiv ist
                    checkbox.setAttribute("checked", "checked");

                    // Erstelle einen Klon aus dem Filter-Badge-Template
                    badgeClone = firstFilterBadge.cloneNode(true);

                    // Befülle die notwendigen Attribute und Inhalte des Templates mit den Daten dieses Filters
                    badgeClone.getElementsByTagName("span")[0].innerHTML =
                        capitalizeLetterAt(
                            checkbox.getAttribute("data-label"),
                            0
                        );
                    badgeClone
                        .getElementsByTagName("button")[0]
                        .setAttribute("data-filter", key + "-" + avalue);

                    // Hänge den Filter an die bestehenden in der Filter-Sektion an
                    badgeClone.classList.remove("d-none");
                    activeFiltersContainer.appendChild(badgeClone);
                    badgeClone
                        .querySelector("button")
                        .addEventListener("click", deleteThatFilter);
                });

                // Blende die Filter-Sektion ein
                activeFiltersWrapper.classList.remove("d-none");
                activeFiltersWrapper.classList.add("d-flex");
            }
        });

        /**
         * ##############################################
         * ############ SCROLLE ZUM FILTER ##############
         * ##############################################
         *
         * Speichere die aktuelle Höhe der Navigation ab. Diese erzeugt nämlich
         * einen Offset den wir beim Scrollen mit einplanen wollen.
         *
         * Speichere auch den aktuellen Cookie prevURL in eine Variable. Hier ist immer die URL
         * gespeichert die man zuvor besucht hat. Wir benötigt dies, um zur Filter-Sektion
         * zu Scrollen sofern welche gesetzt sind. Aber nur im Falle, dass man nicht vorher
         * ein Produkt angesehen hat. Daher die vorherige URL.
         */
        let navbar = document.getElementById("navbar");
        let prevURL = Cookies.get("prevURL");

        // Wenn es Parameter gibt und die zuvor besuchte Seite kein Produkt war ...
        if (url.search.length > 0 && !prevURL.includes("/produkt/")) {
            // ...scrolle zur Filtersektion
            smoothScroll(rangeActionBar.id, navbar.offsetHeight + 20);
        }
    }
});

/**
 * #################################################
 * ###### EVENT-HANDLER FÜR FILTER-CHECKBOXEN ######
 * #################################################
 */
document
    .querySelectorAll('#collapseFilter input[type="checkbox"]')
    .forEach(function (item) {
        item.addEventListener("change", (event) => {
            /**
             * Lösche die parameter "order" und "page" sobald
             * ein Filter verändert wurde
             */
            params.delete("order");
            params.delete("page");

            /**
             * Finde das umschliessende Element der geänderten Checkbox
             * dass diese Filter-Gruppe umgibt
             *
             * Suche nun alle aktuell aktiven Filter-Checkboxes
             *
             * Finde dann über die Filter-Gruppe druch das data-attribute
             * die Art bzw. den Typ des Filter heraus
             *
             * Zuletzt initiiere einen String der alle aktiven Filter für die
             * URL beinhalten wird
             */
            let group = event.target.closest("div.filter-type");
            let checkedFilters = group.querySelectorAll(
                'input[type="checkbox"]:checked'
            );
            let type = group.getAttribute("data-filtertype");
            let filtersString = "";

            /**
             * Wenn es nach dem Klick auf einen Filter noch
             * aktive Filter gibt druchlaufe alle und verkette
             * Sie zu einem String mit dem Trenner "_".
             *
             * Gibt es keine aktiven Filter-Checkboxen mehr, dann
             * lösche alle Parameter aus der URL, die den gleichen Typ
             * haben wie die Checkbox, die gerade geändert wurde
             */
            if (checkedFilters.length > 0) {
                // Verkette alle Parameter in einen String
                checkedFilters.forEach(function (item) {
                    filtersString += "_" + item.value;
                });

                // Lösche das vorderste "_" dass vom Verketten übrig bleibt
                filtersString = filtersString.substring(1);

                // Setze den Parameter neu bzw erzeuge Ihn wenn er noch nicht existiert
                params.set(type, filtersString);
            } else {
                /**
                 * Lösche den gesamten Parameter-Typen aus der URL
                 * wenn es in dieser Filter-Gruppe keine aktive Checkbox gibt
                 */
                params.delete(type);
            }

            /**
             * Sortiere die Parameter und schreibe die neue URL
             * in das Hiddenfield
             */
            params.sort();
            document.getElementById("filter-url").value = params;
        });
    });

/**
 * #################################################
 * ##### EVENT-HANDLER FÜR SUCHDURCHFÜHRUNGEN ######
 * #################################################
 *
 * Wird der Button zum Druchführen einer Suche geklickt,
 * wird der aktuelle Wert aus dem Hiddenfield für die
 * aktiven Filter genommen und hinten an die aktuelle
 * URL gesetzt. Anschliessend wird diese neue Filter-URL
 * direkt aufgerufen
 */
if (typeof applyFilterButton != "undefined" && applyFilterButton != null) {
    applyFilterButton.addEventListener("click", (event) => {
        window.location.href =
            window.location.origin +
            "" +
            window.location.pathname +
            (document.getElementById("filter-url").value.trim() != ""
                ? "?" + document.getElementById("filter-url").value
                : "");
    });
}

/**
 * #################################################
 * ###### EVENT-HANDLER FÜR FILTERENTFERNUNG #######
 * #################################################
 *
 * Wird der Button zum Entfernen aller Filter geklickt,
 * wird direkt die URl aufgerufen, aber ohne die zuvor
 * aktiven Filter
 */
if (typeof deleteFilterButton != "undefined" && deleteFilterButton != null) {
    deleteFilterButton.addEventListener("click", (event) => {
        window.location.href =
            window.location.origin + "" + window.location.pathname;
    });
}

/**
 * #################################################
 * ######## EVENT-HANDLER FÜR FILTER-BADGES ########
 * #################################################
 *
 * Wird der X-Button an einem Filter-Badge geklickt,
 * wird die Deaktivierung des Filters via Klick über
 * die zugehörige Checkbox simuliert und anschliessend
 * ein Klick auf den Button zur Durchführung der Suche
 * getriggert
 */
function deleteThatFilter(event) {
    document
        .querySelector(
            '.filter-checkbox[id="' +
                event.currentTarget.getAttribute("data-filter") +
                '"]'
        )
        .click();
    applyFilterButton.click();
}

/**
 * #################################################
 * ############ USER VERLÄSST EINE SEITE ###########
 * #################################################
 *
 * Wenn der User eine Seite verlässt wird ein Cookie
 * gesetzt (neu erzeugt oder aktualisiert) der die
 * jetzt noch aktuelle URL speichert. So weiß der
 * Browser immer welche URL zuvor bsucht wurde.
 */
window.addEventListener("beforeunload", (event) => {
    Cookies.set("prevURL", window.location.href, {
        expires: 730,
        secure: true,
        sameSite: "strict",
        path: "/",
    });
});

document.querySelectorAll(".product-preview").forEach(function (item) {
    item.addEventListener("mouseenter", (event) => {
        var secondImage = item.querySelector("picture.last");

        if (secondImage) {
            secondImage.style.transition = "opacity 0.5s";
            secondImage.style.opacity = 1;
        }
    });

    item.addEventListener("mouseleave", (event) => {
        var secondImage = item.querySelector("picture.last");

        if (secondImage) {
            secondImage.style.transition = "opacity 0.5s";
            secondImage.style.opacity = 0;
        }
    });

    item.addEventListener("touchstart", (event) => {
        var secondImage = item.querySelector("picture.last");

        if (secondImage) {
            secondImage.style.transition = "opacity 0.5s";
            secondImage.style.opacity = 1;
        }
    });

    item.addEventListener("touchend", (event) => {
        var secondImage = item.querySelector("picture.last");

        if (secondImage) {
            secondImage.style.transition = "opacity 0.5s";
            secondImage.style.opacity = 0;
        }
    });
});
