let banner = document.querySelector(".global-banner");
let bannerID = banner != null ? banner.getAttribute("id") : "";

document.addEventListener("DOMContentLoaded", function () {
    if (banner != null) {
        if (typeof Cookies.get("banners_seen") !== "undefined") {
            var bannersSeen = Cookies.get("banners_seen");
            if (bannersSeen.search(bannerID) < 0) {
                banner.classList.remove("d-none");
            }
        } else {
            banner.classList.remove("d-none");
        }
    }
});

var bannerSeenClickers = document.querySelectorAll(".banner-seen");

bannerSeenClickers.forEach(function (bannerSeenClicker) {
    bannerSeenClicker.addEventListener("click", function (event) {
        banner.classList.add("d-none");
        saveBannerAsSeen(banner);
        banner.remove();
    });
});

var saveBannerAsSeen = (banner) => {
    if (typeof Cookies.get("banners_seen") !== "undefined") {
        var oldList = Cookies.get("banners_seen");
        if (oldList.search(bannerID) < 0) {
            Cookies.set("banners_seen", oldList + "." + bannerID, {
                expires: 14,
                secure: true,
                sameSite: "strict",
                path: "/",
            });
        }
    } else {
        Cookies.set("banners_seen", bannerID, {
            expires: 14,
            secure: true,
            sameSite: "strict",
            path: "/",
        });
    }
};
