document.addEventListener("DOMContentLoaded", function () {
    if (navigator.cookieEnabled) {
        var timerPopups = document.querySelectorAll(
            '.popup[data-event="time"]'
        );

        timerPopups.forEach(function (popup) {
            var thisID = popup.getAttribute("data-popupid");

            console.log("popup", thisID);

            var popups_seen =
                typeof Cookies.get("popups_seen") === "undefined"
                    ? ""
                    : Cookies.get("popups_seen");
            if (popups_seen.search(thisID) < 0) {
                var time = popup.getAttribute("data-eventtimer");
                setTimeout(function () {
                    new Modal(popup, {
                        backdrop: "static",
                        keyboard: false,
                    }).show();
                    // popup.classList.add("show");
                    // popup.style.display = "block";
                }, time);
            }
        });
    }
});

if (navigator.cookieEnabled) {
    var scrollerPopups = document.querySelectorAll(
        '.popup[data-event="scroll"]'
    );

    document.addEventListener("scroll", function () {
        var popups_seen =
            typeof Cookies.get("popups_seen") === "undefined"
                ? ""
                : Cookies.get("popups_seen");
        var currentScrollPos =
            window.pageYOffset ||
            document.documentElement.scrollTop ||
            document.body.scrollTop ||
            0;
        scrollerPopups.forEach(function (popup) {
            var thisID = popup.getAttribute("data-popupid");
            if (popups_seen.search(thisID) < 0) {
                var wantedPos = popup.getAttribute("data-eventscroll");
                if (currentScrollPos >= parseInt(wantedPos)) {
                    new Modal(popup, {
                        backdrop: "static",
                        keyboard: false,
                    }).show();
                    // popup.classList.add("show");
                    // popup.style.display = "block";
                }
            }
        });
    });
}

var popupElements = document.querySelectorAll(".popup");
popupElements.forEach(function (popup) {
    popup.addEventListener("hidden.bs.modal", function (event) {
        if (navigator.cookieEnabled) {
            var modal = event.target;
            savePopupAsSeen(modal);
        }
    });
});

var popupsSeenKlicker = document.querySelectorAll(".popups-seen-klicker");
popupsSeenKlicker.forEach(function (klicker) {
    klicker.addEventListener("click", function (event) {
        if (navigator.cookieEnabled) {
            event.preventDefault();
            var modal = event.target.closest(".modal");
            savePopupAsSeen(modal);

            if (event.target.target == "_blank") {
                window.open(event.target.href, "_blank");
            } else {
                window.location = event.target.href;
            }
        }
    });
});

var savePopupAsSeen = function (modal) {
    var modalID = modal.getAttribute("data-popupid");
    if (typeof Cookies.get("popups_seen") !== "undefined") {
        var oldList = Cookies.get("popups_seen");
        if (oldList.search(modalID) < 0) {
            Cookies.set("popups_seen", oldList + "." + modalID, {
                expires: 14,
                secure: true,
                sameSite: "strict",
                path: "/",
            });
        }
    } else {
        Cookies.set("popups_seen", modalID, {
            expires: 14,
            secure: true,
            sameSite: "strict",
            path: "/",
        });
    }
};
