var frame = document.getElementById('configuratorIframe');

if(frame){
    var intervalHandler;
    window.addEventListener("message", (event) => {
        switch (event.data.type) {
            case 'getLocation':
                clearInterval(intervalHandler);
                frame.contentWindow.postMessage({type: 'location', location: JSON.stringify(window.location)}, '*');
                break;
        }
    }, false);

    frame.onload = () => {
        intervalHandler = setInterval(() => {
            frame.contentWindow.postMessage({type: 'bootstrapped'}, '*');
        }, 100);
    };
}
