// get all anchors inside #desktop-menu-zero that has a data-group-id attribute
let anchors = document.querySelectorAll(
    "#desktop-menu-zero a[data-bs-toggle=collapse]"
);

let anchorsOne = document.querySelectorAll(
    "#desktop-menu-one a[data-bs-toggle=collapse]"
);

// on click on one of the anchors toggle all other anchors so that collapse is only open for one group
anchors.forEach((anchor) => {
    anchor.addEventListener("click", (e) => {
        let collapses = document.querySelectorAll(
            "#desktop-menu-above-zero > .collapse.show"
        );

        collapses.forEach((collapse) => {
            let collapseInstance = Collapse.getInstance(collapse);
            collapseInstance.hide();
        });
    });
});

// on click on one of the anchors toggle all other anchors so that collapse is only open for one group
anchorsOne.forEach((anchor) => {
    anchor.addEventListener("click", (e) => {
        let collapses = document.querySelectorAll(
            "#desktop-menu-above-one > .collapse.show"
        );

        collapses.forEach((collapse) => {
            let collapseInstance = Collapse.getInstance(collapse);
            collapseInstance.hide();
        });
    });
});
