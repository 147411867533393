document.addEventListener("DOMContentLoaded", function () {
    if (body.classList.contains("PrivacyPage")) {
        var allHeadlines = document.querySelectorAll("h2, h3, h4, h5, h6");
        var privacyContentList = document.getElementsByClassName(
            "privacy-content-list"
        )[0];

        allHeadlines.forEach(function (item, index) {
            item.setAttribute("id", "ph-" + index);
            var listItem = document.createElement("li");
            listItem.className = "mb-2 privacy-content-item";

            var listLink = document.createElement("a");
            listLink.href =
                location.href.replace(location.hash, "") + "#ph-" + index;
            listLink.innerHTML = item.innerHTML;

            listItem.append(listLink);
            privacyContentList.append(listItem);
        });
    }

    //buildCookieTables();
});

// function buildCookieTables(){
//     if(body.classList.contains('PrivacyPage')){
//         let necessaryCookiesTable = document.getElementById('necessary-cookies').querySelector('tbody');
//         let notNecessaryCookiesTable = document.getElementById('not-necessary-cookies').querySelector('tbody');

//         let allCookies          = Cookies.get();
//         let necessaryCookies    = ['cookie_consent', 'cookie_groups', 'prevURL'];

//         let cookieDescriptions = [];
//         cookieDescriptions['cookie_consent']    = 'Speichert den Status des Cookie-Hinweises';
//         cookieDescriptions['cookie_groups']     = 'Speichert welche Cookie-Typen verwendet werden dürfen';
//         cookieDescriptions['remember_videos']   = 'Speichert ob Videos sofort freigeschaltet werden dürfen';
//         cookieDescriptions['remember_maps']     = 'Speichert ob Maps sofort freigeschaltet werden dürfen';
//         cookieDescriptions['remember_misc']     = 'Speichert ob iFrames sofort freigeschaltet werden dürfen';
//         cookieDescriptions['enable_marketing']  = 'Speichert die Auswahl ob Marketing-Daten übertragen werden dürfen';
//         cookieDescriptions['_ga']               = 'Identifiziert den User bei Google Analytics';
//         cookieDescriptions['_gid']              = 'Identifiziert den User bei Google Diensten';
//         cookieDescriptions['_GRECAPTCHA']       = 'Identifiziert den User beim Google Recaptcha Dienst';

//         cookieDescriptions['banners_seen']      = 'Prüft ob mit einem Banner schon einmal interagiert wurde und zeigt diesen dann nicht mehr an';

//         cookieDescriptions['prevURL']           = 'Speichert die vorher besuchte URL innerhalb dieser Website';

//         let cookieOfferers = [];
//         cookieOfferers['cookie_consent']    = window.location.hostname;
//         cookieOfferers['cookie_groups']     = window.location.hostname;
//         cookieOfferers['remember_videos']   = window.location.hostname;
//         cookieOfferers['remember_maps']     = window.location.hostname;
//         cookieOfferers['remember_misc']     = window.location.hostname;
//         cookieOfferers['enable_marketing']  = window.location.hostname;

//         cookieOfferers['banners_seen']      = window.location.hostname;
//         cookieOfferers['prevURL']           = window.location.hostname;

//         cookieOfferers['_ga']               = 'Google Inc.';
//         cookieOfferers['_gid']              = 'Google Inc.';
//         cookieOfferers['_GRECAPTCHA']       = 'Google Inc.';

//         for (var key of Object.keys(allCookies)) {
//             let tr = document.createElement("tr");
//             let tdName = document.createElement("td");
//             let tdOfferer = document.createElement("td");
//             let tdPurpose = document.createElement("td");

//             tr.append(tdName);
//             tr.append(tdOfferer);
//             tr.append(tdPurpose);

//             tdName.innerHTML    = key;
//             tdOfferer.innerHTML = typeof cookieOfferers[key] != 'undefined' ? cookieOfferers[key] : '-';
//             tdPurpose.innerHTML = typeof cookieDescriptions[key] != 'undefined' ? cookieDescriptions[key] : '-';

//             if(necessaryCookies.includes(key)){
//                 necessaryCookiesTable.appendChild(tr);
//             }else{
//                 notNecessaryCookiesTable.appendChild(tr);
//             }
//         }
//     }
// }
