let reviewsContainer = document.querySelector('.reviews');
let pos = { left: 0, x: 0 };

if (reviewsContainer != null) {
    reviewsContainer.scrollLeft = 0;
    reviewsContainer.addEventListener('mousedown', mouseDownHandler);
}

function mouseDownHandler(e) {
    reviewsContainer.style.cursor = 'grabbing';
    reviewsContainer.style.userSelect = 'none';
    pos = {
        left: reviewsContainer.scrollLeft,
        x: e.clientX,
    };

    document.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('mouseup', mouseUpHandler);
};

function mouseMoveHandler(e) {
    const dx = e.clientX - pos.x;
    reviewsContainer.scrollLeft = pos.left - dx;
};

function mouseUpHandler() {
    document.removeEventListener('mousemove', mouseMoveHandler);
    document.removeEventListener('mouseup', mouseUpHandler);

    reviewsContainer.style.cursor = 'grab';
    reviewsContainer.style.removeProperty('user-select');
};
